import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import CompanyDetailsService, { CompanyDetailsServiceEquityResponse } from "common/company/CompanyDetailsService";
import { H } from "common/components/atoms/Typography";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import PoolAuthorizedSharesField from "common/pool/components/PoolForm/PoolAuthorizedSharesField";
import PoolExistingShareClassesField from "common/pool/components/PoolForm/PoolExistingShareClassesField";
import PoolShareClassField from "common/pool/components/PoolForm/PoolShareClassField/PoolShareClassField";
import PoolShareholderSearchField from "common/pool/components/PoolForm/PoolShareholderSearchField";
import PoolShareSourceTypeField from "common/pool/components/PoolForm/PoolShareSourceTypeField";
import { createTranslation, TranslationNS } from "translation";

import { PoolTypesOfSource } from "../../../../../../../common/enums/enum";
import PoolSharePriceField from "../../../../../../../common/pool/components/PoolForm/PoolSharePriceField";
import PoolHelper from "../../components/PoolHelper";
import { poolSubmitter } from "../../CreatePoolForm";
import { PoolFormValues } from "../../usePoolForm";

const t = createTranslation(TranslationNS.pages, "createPool.shares");
const Shares = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [equity, setEquity] = useState<CompanyDetailsServiceEquityResponse>();

  useEffect(() => {
    CompanyDetailsService.equity().then(({ data: equity }) => {
      setEquity(equity);
    });
  }, []);

  useToastFormikValidator();
  const { isSubmitting, setFieldValue, values } = useFormikContext<PoolFormValues>();

  const handleBack = useCallback(() => {
    navigate(getEMPath(["createPool", "basic"]), { state });
  }, [navigate, state]);

  const handleSubmitter = useCallback(() => {
    setFieldValue("submitter", poolSubmitter.draft);
  }, [setFieldValue]);

  return (
    <WizardContent.Content step={2}>
      <H.xs className="text-left mb-5">{t("title")}</H.xs>
      <PoolHelper questionId="poolShareSource" answerText={t("shareSourceAnswer")} className="mb-3">
        <PoolShareSourceTypeField />
      </PoolHelper>

      {+values.shareSourceTypeId === PoolTypesOfSource.existing && (
        <PoolHelper questionId="poolSourceStakeholderId" answerText={t("shareholderSearchAnswer")} className="mb-3">
          <PoolShareholderSearchField name="shareSourceStakeholderId" />
        </PoolHelper>
      )}

      {+values.shareSourceTypeId === PoolTypesOfSource.existing ? (
        <PoolHelper questionId="poolShareClass" answerText={t("shareClassAnswer")} className="mb-3">
          <PoolExistingShareClassesField />
        </PoolHelper>
      ) : (
        <PoolHelper questionId="poolShareClass" answerText={t("shareClassAnswer")} className="mb-1">
          <PoolShareClassField />
        </PoolHelper>
      )}

      <PoolHelper
        questionId="poolNumberOfShares"
        answerText={t.el("numberOfSharesAnswer", {
          components: [<Link to="/" key={1}></Link>],
        })}
      >
        <PoolAuthorizedSharesField totalNumberOfShares={equity?.totalNumberOfSharesIssued} />
      </PoolHelper>
      <PoolHelper questionId="poolsharePrice" answerText={t("shares.sharePriceAnswer")} className="mt-5">
        <PoolSharePriceField />
      </PoolHelper>
      <div className="mt-2 d-flex">
        <WizardContent.Controls.BackButton isDisabled={isSubmitting} className="ms-auto" onClick={handleBack} />
        <WizardContent.Controls.ContinueButton
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={handleSubmitter}
          className="ms-2"
        />
      </div>
    </WizardContent.Content>
  );
};
export default Shares;
