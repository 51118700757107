import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Formik, FormikConfig } from "formik";

import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CompanyInformationForm from "./CompanyInformationForm";
import useCompanyInformationForm, { FormGeneralValues } from "./useCompanyInformationForm";

const [translation] = [createTranslation(TranslationNS.pages, "companyProfile.general")];
const companyFeatures = [CompanyFeatures.PlanAndBills];

const CompanyDetails = memo(() => {
  const { companyId } = useParams<{ companyId: string }>();
  const { hasFullAccess } = useFeatures(FEATURES.companyInformation, companyFeatures);

  const { generalInfo } = useStoreState((state) => state.company);
  const { getAccountThunk } = useStoreActions((state) => state.account);
  const { getGeneralInfoThunk } = useStoreActions((state) => state.company);

  const { initialValues, validationSchema } = useCompanyInformationForm();

  const uploadFile = useCallback(
    async (logoFile?: File) => {
      if (logoFile) {
        const formData = new FormData();
        formData.append("ImageFile", logoFile);

        try {
          await axios.post(`/api/attract/logo/${generalInfo?.id}`, formData);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [generalInfo?.id]
  );

  const updateCompanyProfile = useCallback(
    async (values: FormGeneralValues) => {
      try {
        const generalInfoData = {
          id: generalInfo?.id,
          ...values,
          numberOfEmployees: values.numberOfEmployees ? values.numberOfEmployees : 0,
          headquartersCountryId: values.headquartersCountryId ? values.headquartersCountryId : null,
          currencyId: values.currencyId ? values.currencyId : null,
        };

        const request = await axios.post("/api/attract/general-information", generalInfoData);

        if (request.status === 200) {
          await getAccountThunk();

          return true;
        }
      } catch (e) {
        return false;
      }
    },
    [generalInfo?.id, getAccountThunk]
  );

  const submitHandler = useCallback<FormikConfig<FormGeneralValues>["onSubmit"]>(
    async (values) => {
      await uploadFile(values.logoFile);
      const sent = await updateCompanyProfile(values);
      if (sent) {
        notify(translation("notify.changesSaved"), true, "success");
        await getGeneralInfoThunk(companyId ? +companyId : 0);
      }
    },
    [companyId, getGeneralInfoThunk, updateCompanyProfile, uploadFile]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitHandler}
      validationSchema={validationSchema}
    >
      <CompanyInformationForm hasFullAccess={hasFullAccess} />
    </Formik>
  );
});

export default CompanyDetails;
