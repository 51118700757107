import { FC } from "react";
import { useFormikContext } from "formik";

import { fields, PoolFormValues } from "../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { createTranslation, TranslationNS } from "../../../../translation";
import TextField from "../../../components/atoms/TextField/TextField";

type Props = {
  className?: string;
};
const t = createTranslation(TranslationNS.common, "pool.components.poolForm.sharePriceField");
const PoolSharePriceField: FC<Props> = ({ className }) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Pick<PoolFormValues, "sharePrice">>();

  return (
    <TextField
      isOptional
      type="number"
      className={className}
      label={t("label")}
      isTouched={touched.sharePrice}
      error={errors.sharePrice}
      value={values.sharePrice === null ? "" : values.sharePrice}
      name={fields.sharePrice}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default PoolSharePriceField;
