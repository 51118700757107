import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormikContext } from "formik";

import { getPath } from "app/Router/RouterHelper";
import { Button, DatePicker, Dropdown, FileUploadSection, H, P, TextField, Ui } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../CompanyInformation.module.scss";
import { FormGeneralValues } from "./useCompanyInformationForm";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "companyProfile.general"),
  createTranslation(TranslationNS.common, "noAccess"),
];

type FormContainerProps = {
  hasFullAccess: boolean;
};

const CompanyInformationForm: FC<FormContainerProps> = ({ hasFullAccess }) => {
  const navigate = useNavigate();

  const { generalInfo } = useStoreState((state) => state.company);
  const countries = useStoreState((state) => state.common.dropdowns)?.countries;
  const currencies = useStoreState((state) => state.common.dropdowns)?.currencies;
  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit } =
    useFormikContext<FormGeneralValues>();

  const [isDeleteModalActive, setIsDeleteModalActive] = useState<boolean>(false);
  const [removalCompanyName, setRemovalCompanyName] = useState<string>("");
  const [isRemovalSubmitting, setIsRemovalSubmitting] = useState<boolean>(false);

  const handleChangeRemovalCompanyName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRemovalCompanyName(e.target.value);
  }, []);

  const handleModalOpen = useCallback(() => {
    setIsDeleteModalActive(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsDeleteModalActive(false);
  }, []);

  const handleClickSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleChangeDate = useCallback(
    (date: string | Date, name?: string) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  const countriesDropDown = useMemo(() => {
    return countries ? countries : [];
  }, [countries]);

  const currenciesDropDown = useMemo(() => {
    return currencies ? currencies : [];
  }, [currencies]);

  const handleRemoveCompany = async () => {
    try {
      setIsRemovalSubmitting(true);
      const request = await axios.delete(`/api/company/${generalInfo?.id}`);

      if (request.status === 200) {
        setRemovalCompanyName("");
        setIsDeleteModalActive(false);
        await getAccountThunk();
        navigate(getPath(["user", "dashboard"]));
        notify(
          t("deleteCompany.successNotification", {
            companyName: generalInfo?.name,
          }),
          true,
          "success"
        );
      }
    } catch (e) {
      console.error({ e });
    } finally {
      setIsRemovalSubmitting(false);
    }
  };

  return (
    <div className={classes.wrap}>
      <div className="d-flex justify-content-between">
        <div className={classes.fields}>
          <TextField
            name="name"
            value={values.name}
            label={t("name.label")}
            error={errors.name}
            isTouched={touched.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <DatePicker
            isDateOnlyString
            className="mt-4"
            label={t("foundedAt.label")}
            name="foundedAt"
            date={values.foundedAt}
            onBlur={handleBlur}
            onChange={handleChangeDate}
            error={errors.foundedAt}
            isTouched={touched.foundedAt}
          />
          <TextField
            isOptional
            className="mt-4"
            name="organizationNumber"
            value={values.organizationNumber}
            label={t("organizationNumber.label")}
            error={errors.organizationNumber}
            isTouched={touched.organizationNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextField
            type="number"
            className="mt-4"
            label={t("numberOfEmployees.label")}
            value={String(values.numberOfEmployees)}
            isOptional
            name="numberOfEmployees"
            error={errors.organizationNumber}
            isTouched={touched.organizationNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Dropdown
            className="mt-4"
            isOptional
            data-testid="dropdown-toggle-headquarters-location-test-id"
            label={t("headquartersCountry.label")}
            placeholder={t("headquartersCountry.placeholder")}
            options={countriesDropDown}
            optionsIsObject
            name="headquartersCountryId"
            selectedValue={values.headquartersCountryId}
            onChange={handleChange}
          />
          <TextField
            isOptional
            className="mt-4"
            label={t("headquartersCity.label")}
            value={String(values.headquartersCity)}
            name="headquartersCity"
            onChange={handleChange}
          />
          <Dropdown
            className="mt-4"
            label={t("currency")}
            error={errors.currencyId}
            isTouched={touched?.currencyId}
            selectedValue={values.currencyId}
            optionsIsObject
            options={currenciesDropDown}
            name="currencyId"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <div className="me-10">
            <Ui.m className="mb-2">{t("companyLogo.label")}</Ui.m>
            <FileUploadSection
              buttonText={t(values.logoPreview ? "companyLogo.buttonReplace" : "companyLogo.buttonUpload")}
              imageWidth={120}
              imageHeight={120}
              descriptionText=""
              circularImage={false}
              alternativeText="logo"
              defaultImageType="company-logo"
              previewUrl={values.logoPreview}
              setFileToUpload={(file) => {
                setFieldValue("logoFile", file);
              }}
              acceptedFormats=".jpg, .png, .svg"
              isDisabled={!hasFullAccess}
            />
          </div>
        </div>
      </div>

      <div className="mt-9 d-flex justify-content-between">
        <Button
          isLoading={isSubmitting}
          isDisabled={isSubmitting || !hasFullAccess}
          data-testid="company-profile-submit-button"
          tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          onClick={handleClickSubmit}
        >
          {t("save")}
        </Button>

        <Button
          variant="tertiary"
          isLoading={isSubmitting}
          className={classes["remove-btn"]}
          isDisabled={isSubmitting || !hasFullAccess}
          data-testid="company-profile-delete-button"
          tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          onClick={handleModalOpen}
        >
          {t("deleteCompany.formButton")}
        </Button>
      </div>

      <Modal centered show={isDeleteModalActive} contentClassName="py-7 px-9 text-center" onHide={handleModalClose}>
        <H.xs>
          {t("deleteCompany.title", {
            companyName: generalInfo?.name,
          })}
        </H.xs>

        <P.m className="mt-3 mb-4">{t("deleteCompany.description")}</P.m>

        <TextField
          isTouched
          value={removalCompanyName}
          label={t("deleteCompany.inputLabel")}
          onChange={handleChangeRemovalCompanyName}
          error={
            removalCompanyName.trim() && removalCompanyName.trim() !== generalInfo?.name
              ? t("deleteCompany.inputError")
              : undefined
          }
        />

        <div className="mt-5 d-flex justify-content-center">
          <Button
            variant="danger"
            className="me-2"
            isLoading={isRemovalSubmitting}
            isDisabled={isRemovalSubmitting || !hasFullAccess || removalCompanyName.trim() !== generalInfo?.name}
            onClick={handleRemoveCompany}
          >
            {t("deleteCompany.delete")}
          </Button>

          <Button variant="secondary" isLoading={isRemovalSubmitting} onClick={handleModalClose}>
            {t("deleteCompany.cancel")}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default CompanyInformationForm;
