import { Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";

import AccessControl from "./access-control/AccessControl";
import BankInformation from "./bank-details/BankDetails";
import CompanySettings from "./company-settings/CompanySettings";

const SettingRouter = () => {
  return (
    <Routes>
      <Route path={ROUTER_V2.equityManagement.settings.accessControl} element={<AccessControl />} />
      <Route path={ROUTER_V2.equityManagement.settings.bankDetails} element={<BankInformation />} />
      <Route path={ROUTER_V2.equityManagement.settings.companySettings} element={<CompanySettings />} />
    </Routes>
  );
};

export default SettingRouter;
