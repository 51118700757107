import { FC } from "react";
import Image from "react-bootstrap/Image";
import { generatePath, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { getEMPath, getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import { H, P, Ui } from "common/components/atoms/Typography";
import { ImageIcon, PoolsIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Card.module.scss";

export type CompanyCardProps = {
  id: number;
  name: string;
  logoFilePath: string;
  numberOfPlans: number;
  numberOfSharesInPlans: number;
  introduction?: string;
  width?: number;
  isSingle?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.homeDashboard");

const CompanyCard: FC<Omit<JSX.IntrinsicElements["div"], "id"> & CompanyCardProps> = ({
  id,
  name,
  logoFilePath,
  numberOfPlans,
  width,
  isSingle,
  introduction,
  numberOfSharesInPlans,
  className,
  ...props
}) => {
  const navigate = useNavigate();

  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const { setCompanyId } = useStoreActions((action) => action.activeCompanyModel);

  const onButtonPress = (attract?: boolean) => {
    const routeToGo = attract
      ? getEMPath(["settings", "companySettings"], { companyId: id })
      : getPath(["equityManagement", "ownership", "capTable"], { companyId: id });

    if (isSingle) {
      navigate(routeToGo);

      return;
    }

    if (activeCompanyId === id) {
      navigate(routeToGo);

      return;
    }

    setCompanyId(id);
    navigate(generatePath(routeToGo, { companyId: id.toString() }));
  };

  return (
    <div
      className={classNames(className, classes["card"], {
        [classes["company"]]: true,
      })}
      style={{ width }}
      {...props}
    >
      {logoFilePath ? (
        <Image src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${logoFilePath}`} alt="profile" />
      ) : (
        <div className={classes["empty-logo"]}>
          <ImageIcon strokeWidth={1} />
        </div>
      )}

      {isSingle && <H.xxxs className="mb-2 mt-8">{t("manageEquityTitle")}</H.xxxs>}

      <H.xs className="mt-4">{name}</H.xs>

      {introduction && (
        <P.s
          className={classNames(classes["introduction"], {
            "mt-1": !isSingle,
          })}
        >
          {introduction}
        </P.s>
      )}

      <div className={classNames(classes["plan-details"])} style={{ marginTop: "auto" }}>
        <div className={classes["icon"]}>
          <PoolsIcon />
        </div>

        <div className="d-flex flex-column justify-content-between ms-1">
          <Ui.s className="fw-500" style={{ color: scssVariables.foregroundHigh }}>
            {t("planCount", {
              count: numberOfPlans,
            })}
          </Ui.s>

          <Ui.s style={{ color: scssVariables.foregroundMedium }}>
            {t("sharesCount", {
              shares: Intl.NumberFormat("en", { notation: "compact" }).format(numberOfSharesInPlans),
            })}
          </Ui.s>
        </div>
      </div>

      <div className="mt-4 d-flex">
        <Button
          size="s"
          variant="primary"
          className="me-2"
          style={{ width: "max-content" }}
          onClick={onButtonPress.bind(null, false)}
        >
          {t(isSingle ? "manageEquityBtn" : "manageCompany")}
        </Button>

        {/*<Button
          size="s"
          variant="secondary"
          style={{ width: "max-content", color: scssVariables.foregroundHigh }}
          onClick={onButtonPress.bind(null, true)}
        >
          {t("viewPitch")}
        </Button>*/}
      </div>
    </div>
  );
};

export default CompanyCard;
