import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { defaultTo, isEmpty, isNil } from "ramda";

import { Button, ChecksGroup, DatePicker, ModalInfo, P, Tag, TextArea, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, ArrowUpIcon, CalendarIcon, CloseIcon, TimeIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../transactions.context";
import {
  ConfirmIssueSharesTransactionPostDTO,
  ConfirmSplitTransactionPostDTO,
  Transaction,
  TransactionCategory,
  TransactionCategoryIds,
  TransactionSharesType,
} from "../../types";
import formatTransactionDate from "../format-transaction-date";
import { TransactionDetailsFields } from "../forms/form-sections/transaction-details/form-fields";
import bundleClasses from "../transactions-list/transaction-item/transaction-headers/capital-inscrease-header/capital-increase-header.module.scss";
import classes from "./confirm-transaction-modal.module.scss";

type PropsTypes = {
  onClose: () => void;
  onSubmit: (
    confirmTransactionData: ConfirmIssueSharesTransactionPostDTO | ConfirmSplitTransactionPostDTO,
    transactionCategoryId: TransactionCategoryIds
  ) => Promise<boolean>;
  transaction: Transaction;
  minTransactionDate?: string;
};

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions.confirmTransactionModal"),
  createTranslation(TranslationNS.validation),
];

const ConfirmTransactionModal: FC<PropsTypes> = ({ onClose, onSubmit, transaction, minTransactionDate }) => {
  const fNumber = useFormatNumbers(transaction.currencySymbol);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(transaction.canEditConfirmed);
  const [transactedAt, setTransactedAt] = useState<string | undefined>(transaction.transactedAt);
  const [description, setDescription] = useState(transaction.description);

  // not showing warning block initially and varible to close warning section
  const [isBundleTransactionsMatch, setIsBundleTransactionsMatch] = useState(true);
  const [isBundleWarningClose, setIsBundleWarningClose] = useState(false);

  const capitalIncreaseDetails = TransactionsContext.useStoreState((state) => state.capitalIncreaseDetails);
  const getCapitalIncrease = TransactionsContext.useStoreActions((actions) => actions.getCapitalIncrease);

  const isIssueOrBuySell = useMemo(() => {
    return transaction.categoryId === TransactionCategory.Issue || transaction.categoryId === TransactionCategory.Sell;
  }, [transaction]);

  const isSplit = useMemo(() => {
    return transaction.categoryId === TransactionCategory.Split;
  }, [transaction]);

  const isBundle = useMemo(() => {
    return transaction.categoryId === TransactionCategory.CapitalIncrease;
  }, [transaction]);

  const isNominalValue = useMemo(() => {
    return transaction.categoryId === TransactionCategory.ChangeNominalValue;
  }, [transaction]);

  const minDateInfo = useMemo(() => {
    if (minTransactionDate && (isSplit || isBundle || isNominalValue)) {
      return t("minDateInfo", { minDate: transformDateToCommonDateFormat(minTransactionDate) });
    }
  }, [isBundle, isSplit, isNominalValue, minTransactionDate]);

  // get list of transactions for bundle transaction

  const getCapitalIncreaseRequest = useCallback(async () => {
    try {
      setIsLoading(true);
      await getCapitalIncrease(Math.abs(transaction.transactionId));
    } catch (e) {
      console.error({ e });
    } finally {
      setIsLoading(false);
    }
  }, [getCapitalIncrease, transaction.transactionId]);

  const handleSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      setIsLoading(true);

      // buySell confirmed by default
      if (isIssueOrBuySell) {
        await onSubmit(
          {
            transactionId: transaction.transactionId,
            transactedAt: defaultTo("", transactedAt),
            description,
            transactionBundleId: transaction.transactionBundleId ? -transaction.transactionBundleId : undefined,
          },
          transaction.categoryId
        );
      }

      if (isSplit) {
        await onSubmit(
          {
            transactionId: transaction.transactionId,
            transactedAt: defaultTo("", transactedAt),
            description,
            multiplier: transaction.multiplier,
          },
          transaction.categoryId
        );
      }

      if (transaction.categoryId === TransactionCategory.ChangeNominalValue) {
        await onSubmit(
          {
            transactionId: transaction.transactionId,
            transactedAt: defaultTo("", transactedAt),
            description,
          },
          transaction.categoryId
        );
      }

      if (isBundle && transaction.transactionBundleId) {
        await onSubmit(
          {
            transactionId: transaction.transactionBundleId,
            transactedAt,
            description,
          },
          transaction.categoryId
        );
      }

      setIsLoading(false);
    },
    [
      description,
      isBundle,
      isIssueOrBuySell,
      isSplit,
      onSubmit,
      transactedAt,
      transaction.categoryId,
      transaction.multiplier,
      transaction.transactionBundleId,
      transaction.transactionId,
    ]
  );

  useEffect(() => {
    if (isBundle) {
      getCapitalIncreaseRequest();
    }
  }, [getCapitalIncreaseRequest, isBundle]);

  useEffect(() => {
    if (isBundle) {
      const data = defaultTo([] as Transaction[], capitalIncreaseDetails?.transactions).reduce(
        (acc, curr) => acc + curr.numberOfShares,
        0
      );

      if (capitalIncreaseDetails?.totalIncrease !== data) {
        setIsBundleTransactionsMatch(false);
      }
    }
  }, [capitalIncreaseDetails?.totalIncrease, capitalIncreaseDetails?.transactions, isBundle]);

  return (
    <ModalInfo
      show={true}
      header={t(isBundle ? "capitalIncreaseConfirmTitle" : "title")}
      handleClose={isLoading ? undefined : onClose}
      dialogClassName={classes.modal}
    >
      <form onSubmit={handleSubmit} className="mt-3" style={{ textAlign: "start" }}>
        <div className="p-2 mb-3" style={{ backgroundColor: scssVariables.element2 }}>
          {isBundle && (
            <div className="d-flex mb-2">
              <Ui.m bold className="me-1">
                {transaction.eventName}
              </Ui.m>
              <Ui.xs className={bundleClasses.sizeOfBundle}>{transaction.numberOfBundledTransactions}</Ui.xs>
            </div>
          )}

          {isSplit && (
            <div className="d-flex">
              <Ui.s bold className="mb-1 me-half">
                {fNumber(transaction.numberOfSharesBefore, "amount")}
              </Ui.s>
              <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
              <Ui.s bold className="ms-half">{`${fNumber(transaction.numberOfShares, "amount")} ${t("shares")} • ${
                transaction.categoryName
              } 1:${transaction.multiplier}`}</Ui.s>
            </div>
          )}

          {isNominalValue && (
            <div className="d-flex">
              <Ui.s bold className="mb-1">
                {defaultTo(0, transaction.sharePriceBefore) > defaultTo(0, transaction.sharePrice)
                  ? "Nominal value decreased"
                  : "Nominal value increased"}
              </Ui.s>
            </div>
          )}

          {isIssueOrBuySell && (
            <Ui.s bold className="mb-1">{`${transaction.numberOfShares} ${t(
              TransactionSharesType[transaction.categoryId]
            )} • ${transaction.typeName}`}</Ui.s>
          )}

          <div className="d-flex align-items-center">
            <Tag variant="information" className="d-flex align-items-center me-3">
              {isNominalValue ? (
                <>
                  <TimeIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
                  <Ui.xs>{formatTransactionDate(defaultTo(new Date().toDateString(), transactedAt))}</Ui.xs>
                </>
              ) : (
                <>
                  <CalendarIcon height={16} width={16} color={scssVariables.foregroundLow} className="me-half" />
                  <Ui.xs>{formatTransactionDate(defaultTo(new Date().toDateString(), transactedAt))}</Ui.xs>
                </>
              )}
            </Tag>
            {isSplit && (
              <div className="d-flex">
                <Ui.xs className="me-half">
                  {`${fNumber(transaction.sharePriceBefore, "sharePrice")}
                  /${t("shares")}`}
                </Ui.xs>

                <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />

                <Ui.xs className="ms-half">{`${fNumber(transaction.sharePrice, "sharePrice")}/${t("shares")}`}</Ui.xs>
              </div>
            )}
            {isIssueOrBuySell && (
              <div className="d-flex">
                <Ui.xs className="me-half">{transaction.fromName || "..."}</Ui.xs>
                <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
                <Ui.xs className="ms-half">{transaction.toName || "..."}</Ui.xs>
              </div>
            )}
            {isNominalValue && (
              <div className="d-flex">
                <Ui.xs className="me-half">{fNumber(transaction.sharePriceBefore, "sharePrice")}</Ui.xs>

                <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />

                <Ui.xs className="ms-half">{fNumber(transaction.sharePrice, "sharePrice")}</Ui.xs>
              </div>
            )}
            {isBundle && (
              <div className="d-flex">
                <ArrowUpIcon height={16} width={16} color={scssVariables.foregroundLow} />
                <Ui.xs className="ms-half">{fNumber(transaction.numberOfShares, "amount") + " " + t("shares")}</Ui.xs>
              </div>
            )}
          </div>
        </div>

        <P.m className="mb-4">
          {isBundle
            ? t.el("explanationBundle", {
                components: [<strong key={1} />],
                values: { numberOfTransactions: transaction.numberOfBundledTransactions },
              })
            : t("explanation")}
        </P.m>

        {!isBundleTransactionsMatch && !isBundleWarningClose ? (
          <div className={cn("p-2 d-flex mb-4", classes["shares-not-match"])}>
            <WarningIcon fontSize={24} color={scssVariables.warning900} className="me-2" />

            <div>
              <Ui.m bold className="mb-1">
                {t("sharesNotMatch")}
              </Ui.m>
              <P.s>{t("sharesNotMatchDescription")}</P.s>
            </div>

            <CloseIcon
              fontSize={24}
              className={classes["close-btn"]}
              color={scssVariables.foregroundMedium}
              onClick={() => {
                setIsBundleWarningClose(true);
              }}
            />
          </div>
        ) : null}

        <DatePicker
          // name={TransactionDetailsFields.transactedAt}
          name="transactionDate"
          label={t("transactionDate")}
          date={transactedAt}
          isDateOnlyString
          isWithTimeSelect
          onChange={(date) => {
            setTransactedAt((date ? date : undefined) as string);
          }}
          className="w-50 mb-4"
          minDate={isBundle ? undefined : minTransactionDate ? new Date(minTransactionDate) : undefined}
          info={minDateInfo}
          isTouched
          error={isNil(transactedAt) || isEmpty(transactedAt) ? tValidation("required") : undefined}
        />

        <TextArea
          isOptional
          label={t("description")}
          placeholder={t("descriptionPlaceholder")}
          name={TransactionDetailsFields.description}
          id={TransactionDetailsFields.description}
          onChange={(e) => setDescription(e.target.value)}
          value={description || ""}
          className="mb-4"
        />

        {!transaction.canEditConfirmed && (
          <ChecksGroup className="mb-5">
            <ChecksGroup.Check
              customLabel={<P.m className="ms-1">{t("submitWarning")}</P.m>}
              type="checkbox"
              onChange={(e) => setIsSubmitAllowed(e.target.checked)}
              checked={isSubmitAllowed}
            />
          </ChecksGroup>
        )}

        <div className="d-flex justify-content-center">
          <Button
            type="submit"
            isLoading={isLoading}
            isDisabled={!isSubmitAllowed || isNil(transactedAt) || isEmpty(transactedAt)}
            className="me-2"
          >
            {t("confirmBtn")}
          </Button>
          <Button variant="secondary" isDisabled={isLoading} onClick={onClose}>
            {t("cancelBtn")}
          </Button>
        </div>
      </form>
    </ModalInfo>
  );
};

export default ConfirmTransactionModal;
