import React, { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";
import { isNil } from "ramda";
import * as Yup from "yup";

import { SlidePanel } from "common/components/atoms";
import { UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum, UploadFileTypes } from "common/enums/enum";
import useUpdateCompanyDocuments from "common/hooks/useUpdateCompanyDocuments";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import TransactionsContext from "../../../transactions.context";
import { useTransactionsService } from "../../../transactions-service";
import { Transaction, TransactionCategory } from "../../../types";
import ManageDocumentsDetails from "./manage-documents-details";
import ManageDocumentsForm from "./manage-documents-form";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions.manageDocuments"),
  createTranslation("validation"),
];

type Props = {
  transaction: Transaction | null;
  onClose: () => void;
  setTransactions: (val: Transaction[]) => void;
  updateCurrentTransaction: (val: Transaction | null) => void;
  getAllTransactions?: () => void;
};

type ManageDocumentsFormValues = {
  documentStatusId: number;
  newFiles: File[];
  oldFiles: UploadedFile[];
  companyFileType?: UploadFileTypes;
  entityId: number;
  companyId: string;
};

const validationSchema = () =>
  Yup.object().shape({
    documentStatusId: Yup.number().required(tValidation("required")),
    newFiles: Yup.array().when("documentStatusId", {
      is: DocumentStatusEnum.DOCUMENT_UPLOADED,
      then: Yup.array().test("newFiles", tValidation("required"), (documents: any, obj) => {
        return documents && (documents?.length > 0 || obj.parent?.oldFiles?.length > 0);
      }),
    }),
  });

const ManageDocumentsPanel: FC<Props> = (props) => {
  const show = !isNil(props.transaction);

  return (
    <SlidePanel show={show}>
      <SlidePanel.Header title={t("title")} onHide={props.onClose} />
      <ManageDocumentsDetails transaction={props.transaction} />
      <ManageDocumentsFormik {...props} />
    </SlidePanel>
  );
};

const ManageDocumentsFormik: FC<Props> = ({ transaction, setTransactions, onClose }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const { getTransactions } = useTransactionsService(companyId);

  const getCapitalIncrease = TransactionsContext.useStoreActions((actions) => actions.getCapitalIncrease);

  const { updateCompanyDocuments } = useUpdateCompanyDocuments();

  const documentsFileType = useMemo<UploadFileTypes | undefined>(() => {
    if (transaction?.categoryId === TransactionCategory.Issue) {
      return UploadFileTypes.ShareIssuanceDocument;
    }
    if (transaction?.categoryId === TransactionCategory.Sell) {
      return UploadFileTypes.BuySellDocument;
    }
    if (transaction?.categoryId === TransactionCategory.Split) {
      return UploadFileTypes.SplitTransaction;
    }
    if (transaction?.categoryId === TransactionCategory.ChangeNominalValue) {
      return UploadFileTypes.NominalValueTransaction;
    }
    if (transaction?.categoryId === TransactionCategory.CapitalIncrease) {
      return UploadFileTypes.TransactionBundle;
    }
  }, [transaction?.categoryId]);

  const submitHandler = useCallback<FormikConfig<ManageDocumentsFormValues>["onSubmit"]>(
    async (values) => {
      try {
        if (values.companyFileType) {
          await updateCompanyDocuments(
            values.companyId,
            values.entityId,
            values.companyFileType,
            values.newFiles,
            values.oldFiles,
            values.documentStatusId
          );
        }

        notify(t("transactionUpdated"), true, "success");
        const transactions = await getTransactions();

        if (transaction?.transactionBundleId) {
          await getCapitalIncrease(transaction.transactionBundleId!);
        }

        setTransactions(transactions.transactions);
        onClose();
      } catch (e) {
        console.error({ e });
      }
    },
    [
      getCapitalIncrease,
      getTransactions,
      onClose,
      setTransactions,
      transaction?.transactionBundleId,
      updateCompanyDocuments,
    ]
  );

  const initialValues = useMemo<ManageDocumentsFormValues>(() => {
    return {
      companyId,
      documentStatusId: transaction?.documentStatusId || DocumentStatusEnum.REVIEW_LATER,
      newFiles: [],
      oldFiles: transaction?.files || [],
      companyFileType: documentsFileType,
      entityId: Math.abs(transaction?.transactionId || 0),
    };
  }, [companyId, documentsFileType, transaction?.documentStatusId, transaction?.files, transaction?.transactionId]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      <ManageDocumentsForm onClose={onClose} />
    </Formik>
  );
};

export default ManageDocumentsPanel;
