import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Formik, FormikConfig } from "formik";
import { defaultTo } from "ramda";

import { notify } from "common/utils/notify/notifyFunction";

import classes from "./IncentiveAgreementsSettings.module.scss";
import IncentiveAgreementsSettingsForm from "./IncentiveAgreementsSettingsForm";

export type StockOptionsSettingsFormValues = Pick<
  StockOptionsSettingsGetDto,
  "calculationMethod" | "includeUnusedAuthorizedShares" | "includeValueOfFinancialInstruments"
> & {
  companyId: number;
};

export type StockOptionsSettingsGetDto = {
  calculationMethod?: number;
  includeUnusedAuthorizedShares?: boolean;
  includeValueOfFinancialInstruments?: boolean;
  sharePrice: number;
  fdSharePrice: number;
  fdSharePriceWithValue: number;
  fdWithUnusedPoolsSharePrice: number;
  fdWithUnusedPoolsSharePriceWithValue: number;
};

export const fields: {
  [key in keyof Required<StockOptionsSettingsFormValues>]: key;
} = {
  calculationMethod: "calculationMethod",
  includeUnusedAuthorizedShares: "includeUnusedAuthorizedShares",
  includeValueOfFinancialInstruments: "includeValueOfFinancialInstruments",
  companyId: "companyId",
} as const;

export enum SoCalculatedType {
  basedOnOutstandingShares = 1,
  basedOnFullyDilutedShares = 2,
}

const IncentiveAgreementsSettings = memo(() => {
  const { companyId } = useParams<{ companyId: string }>();
  const [soSettings, setSoSettings] = useState<StockOptionsSettingsGetDto | null>(null);

  const onSubmit = useCallback<FormikConfig<StockOptionsSettingsFormValues>["onSubmit"]>(async (values) => {
    try {
      await axios.post("/api/company/incentive-agreements-settings", values);
      notify("You successfully saved settings", true, "success");
    } catch (error) {
      console.error(error);
    }
  }, []);

  const initialValues = useMemo<StockOptionsSettingsFormValues>(
    () => ({
      calculationMethod: defaultTo(SoCalculatedType.basedOnOutstandingShares, soSettings?.calculationMethod),
      includeUnusedAuthorizedShares: defaultTo(false, soSettings?.includeUnusedAuthorizedShares),
      includeValueOfFinancialInstruments: defaultTo(false, soSettings?.includeValueOfFinancialInstruments),
      companyId: companyId ? +companyId : 0,
    }),
    [
      companyId,
      soSettings?.includeUnusedAuthorizedShares,
      soSettings?.includeValueOfFinancialInstruments,
      soSettings?.calculationMethod,
    ]
  );

  useEffect(() => {
    axios
      .get<StockOptionsSettingsGetDto>(`/api/company/incentive-agreements-settings/${companyId}`)
      .then((response) => {
        setSoSettings(response.data);
      });
  }, [companyId]);

  return (
    <div className={classes.wrap}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        <IncentiveAgreementsSettingsForm sharePrices={soSettings} />
      </Formik>
    </div>
  );
});

export default IncentiveAgreementsSettings;
