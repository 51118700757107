import axios from "axios";
import { action, createContextStore, thunk } from "easy-peasy";

import { transactionsApiBase } from "./transactions-service";
import { CapitalIncreaseGetDTO, TransactionsContextModel } from "./types";

const TransactionsContext = createContextStore<TransactionsContextModel>({
  isLoading: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  capitalIncreaseDetails: null,
  setCapitalIncreaseDetails: action((state, payload) => {
    state.capitalIncreaseDetails = payload;
  }),
  getCapitalIncrease: thunk(async (actions, id) => {
    try {
      const response = await axios.get<CapitalIncreaseGetDTO>(`${transactionsApiBase}/capital-increase/details/${id}`);
      if (response.status === 200) {
        actions.setCapitalIncreaseDetails(response.data);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }),
});

export default TransactionsContext;
