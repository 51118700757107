import { FC, ReactNode } from "react";
import Nav from "react-bootstrap/Nav";
import { NavLinkProps } from "react-bootstrap/NavLink";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { Ui } from "common/components/atoms/Typography";

import classes from "./Menu.module.scss";

export type MenuItem = NavLinkProps & {
  icon?: ReactNode;
  label: ReactNode;
  to: string;
  subLabel?: ReactNode;
  isDisabled?: boolean;
  tooltip?: ReactNode;
};

export type MenuProps = JSX.IntrinsicElements["div"] & {
  title?: ReactNode;
  items: MenuItem[];
};

const Menu: FC<MenuProps> = ({ className, title, items, ...props }) => {
  const location = useLocation();

  return (
    <nav className={cn(classes.menu, className)} {...props}>
      {!!title && (
        <Ui.xs color="foregroundLow" className="py-1_half px-2 mb-semihalf">
          {title.toUpperCase()}
        </Ui.xs>
      )}

      <Nav className={cn("flex-column", classes.menu)} variant="pills">
        {items.map(({ icon, label, subLabel, tooltip, isDisabled, ...props }, index) =>
          tooltip ? (
            <Tooltip placement="right" popupContent={tooltip} key={index}>
              <Nav.Link
                key={`Key for single nav link item is - ${label} - ${index}`}
                as={NavLink}
                tabIndex={-1}
                disabled={isDisabled}
                className={classes.link}
                to={"#"}
              >
                <div>{icon}</div>

                <div className="ms-1">
                  {<Ui.s>{label}</Ui.s>}
                  {subLabel ? <Ui.xs>{subLabel}</Ui.xs> : null}
                </div>
              </Nav.Link>
            </Tooltip>
          ) : (
            <Nav.Link
              key={`Key for single nav link item is - ${label} - ${index}`}
              as={NavLink}
              tabIndex={-1}
              disabled={isDisabled}
              className={cn("mx-0", classes.link, {
                [classes.active]: location.pathname === props.to,
              })}
              {...props}
            >
              {icon}

              <div>
                <Ui.s>{label}</Ui.s>
                {subLabel ? <Ui.xs color="foregroundLow">{subLabel}</Ui.xs> : null}
              </div>
            </Nav.Link>
          )
        )}
      </Nav>
    </nav>
  );
};

export default Menu;
