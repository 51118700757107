import { useMemo } from "react";
import { defaultTo } from "ramda";

import { UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum, PoolTypesOfSource } from "common/enums/enum";
import { PoolCreateOrUpdateDTO, PoolGetDTO } from "store/modelTypes";

export const fields = {
  companyId: "companyId",
  id: "id",
  name: "name",
  description: "description",
  shareSourceTypeId: "shareSourceTypeId",
  shareSourceStakeholderId: "shareSourceStakeholderId",
  shareClassId: "shareClassId",
  numberOfShares: "numberOfShares",
  documentStatusId: "documentStatusId",
  approvalDate: "approvalDate",
  approvalBodyId: "approvalBodyId",
  expiryDate: "expiryDate",
  allowedNumberOfShares: "allowedNumberOfShares",
  filesData: "filesData",
  sharePrice: "sharePrice",
  submitter: "submitter",
} as const;

export type PoolFormValues = PoolCreateOrUpdateDTO & {
  allowedNumberOfShares: number;
  submitter: "draft" | "create" | "postpone";
  filesData: {
    files: File[];
    oldFiles?: PoolGetDTO["documentFiles"] | UploadedFile[];
  };
};

const usePoolForm = (pool?: PoolGetDTO | null, companyId?: number | string) => {
  const initialValues = useMemo<PoolFormValues>(
    () => ({
      [fields.submitter]: "draft",
      [fields.companyId]: defaultTo(companyId ? Number(companyId) : 0, pool?.companyId),
      [fields.id]: defaultTo(undefined, pool?.poolId),
      [fields.name]: defaultTo("", pool?.name),
      [fields.description]: defaultTo("", pool?.description),
      [fields.shareSourceTypeId]: defaultTo(PoolTypesOfSource.new, pool?.shareSourceTypeId),
      [fields.shareSourceStakeholderId]: defaultTo(undefined, pool?.shareSourceStakeholderId),
      [fields.shareClassId]: defaultTo("", pool?.shareClassId),
      [fields.numberOfShares]: defaultTo(0, pool?.numberOfShares),
      [fields.allowedNumberOfShares]: 0,
      [fields.approvalDate]: defaultTo(undefined, pool?.approvedAt),
      [fields.approvalBodyId]: defaultTo(undefined, pool?.approvalBodyId),
      [fields.expiryDate]: defaultTo(undefined, pool?.expiryDate),
      [fields.documentStatusId]: defaultTo(DocumentStatusEnum.NO_DOCUMENT_REQUIRED, pool?.documentStatusId),
      [fields.sharePrice]: defaultTo(undefined, pool?.sharePrice),
      [fields.filesData]: { files: [], oldFiles: pool?.documentFiles || [] },
    }),
    [
      companyId,
      pool?.approvalBodyId,
      pool?.approvedAt,
      pool?.companyId,
      pool?.description,
      pool?.documentFiles,
      pool?.documentStatusId,
      pool?.expiryDate,
      pool?.name,
      pool?.numberOfShares,
      pool?.poolId,
      pool?.shareClassId,
      pool?.sharePrice,
      pool?.shareSourceStakeholderId,
      pool?.shareSourceTypeId,
    ]
  );

  return { initialValues };
};

export default usePoolForm;
