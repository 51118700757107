import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { LoaderContent } from "common/components/atoms";
import Tag from "common/components/atoms/Tag/Tag";
import ResendEmailInviteModal from "common/components/organisms/stakeholder-details/components/ResendEmailInviteModal/ResendEmailInviteModal";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { createTranslation, TranslationNS } from "translation";

import InvitationSentModal from "../stakeholders-management/components/invitation-sent-modal/invitation-sent-modal";
import InviteStakeholdersModal from "../stakeholders-management/components/invite-stakeholders-modal/invite-stakeholders-modal";
import Actual from "./Actual/Actual";
import classes from "./CapTable.module.scss";
import AddMoreShareholders from "./common/AddMoreShareholders/AddMoreShareholders";
import FullyDiluted from "./FullyDiluted/FullyDiluted";
import CapTableStore from "./store";

const CapTableVariants = {
  Diluted: "Diluted",
  Actual: "Actual",
} as const;

export type CapTableVariantsType = keyof typeof CapTableVariants;

const t = createTranslation(TranslationNS.pages, "company.capTable");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const CapTable: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId } = useParams<{ companyId: string }>();

  const { isLoading } = CapTableStore.useStoreState((state) => state);
  const { dilutedData } = CapTableStore.useStoreState((state) => state);
  const selectedDateForActualCapTable = CapTableStore.useStoreState((state) => state.selectedDateForActualCapTable);
  const { getActualDataThunk, getDilutedDataThunk } = CapTableStore.useStoreActions((actions) => actions);

  const [dataAccepted, setDataAccepted] = useState<boolean>(false);
  const [capTableVariant, setCapTableVariant] = useState<CapTableVariantsType>(CapTableVariants.Actual);

  const fetchCaptableData = useCallback(() => {
    try {
      if (companyId) {
        capTableVariant === CapTableVariants.Actual
          ? getActualDataThunk({
              companyId: +companyId,
              date: selectedDateForActualCapTable,
            })
          : getDilutedDataThunk({ companyId: +companyId, valuation: dilutedData?.defaultSimulatedValuation });
      }
    } finally {
      setDataAccepted(true);
    }
  }, [
    capTableVariant,
    companyId,
    dilutedData?.defaultSimulatedValuation,
    getActualDataThunk,
    getDilutedDataThunk,
    selectedDateForActualCapTable,
  ]);

  useEffect(() => {
    fetchCaptableData();
    // eslint-disable-next-line
  }, [capTableVariant, companyId, selectedDateForActualCapTable]);

  useEffect(() => {
    if (capTableVariant === CapTableVariants.Diluted && dataAccepted) {
      fetchCaptableData();
    }
  }, [capTableVariant, dataAccepted, fetchCaptableData]);

  const capTableActualAccess = useFeatures(FEATURES.capTable);

  return (
    <PageContent data-testid="cap-table-page-test-id">
      <PageContent.Header className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!capTableActualAccess.hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>
      </PageContent.Header>
      <AddMoreShareholders />
      <LoaderContent show={isLoading}>
        <div className={classes["cap-table"]}>
          <div>
            {capTableVariant === CapTableVariants.Actual && (
              <Actual capTableVariant={capTableVariant} setCapTableVariant={setCapTableVariant} />
            )}
            {capTableVariant === CapTableVariants.Diluted && (
              <FullyDiluted capTableVariant={capTableVariant} setCapTableVariant={setCapTableVariant} />
            )}
          </div>
        </div>
      </LoaderContent>

      <InvitationSentModal />
      <InviteStakeholdersModal onClose={fetchCaptableData} />
      <ResendEmailInviteModal />
    </PageContent>
  );
};

export default CapTable;
